import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import DocumentTitle from 'react-document-title';
import { values } from 'lodash';
import moment from 'moment-timezone';
import jstz from 'jstz';
import { loadNotifications } from '../actions';
import { getConfig, stripHtml } from '../utils/helpers';
import { alertTypes } from '../utils/constants';
const timezone = jstz.determine().name();

class Notifications extends Component {
  componentDidMount() {
    this.loadNotifications();
  }

  loadNotifications = () => {
    this.props.loadNotifications(getConfig('marketplaceId'));
  };

  markAsRead = item => {
    const notificationItems = this.props.notifications.items[0].receiverUserId;

    const url =
      `${getConfig('apiRoot')}notification-read` +
      `?userid=${notificationItems}` +
      `&mpId=${getConfig('marketplaceId')}` +
      `&apikey=${this.props.cookies.get('apiKey')}`;

    fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify([
        { notificationId: item.notificationId, markAsRead: true },
      ]),
    })
      .then(res => res.json())
      .catch(error => console.error('Error:', error))
      .then(response => this.loadNotifications(getConfig('marketplaceId')));
  };

  markAsReadQuick = item => {
    const notificationItems = this.props.notifications.items[0].receiverUserId;

    const url =
      `${getConfig('apiRoot')}` +
      `notification-read` +
      `?userid=${notificationItems}` +
      `&mpId=${getConfig('marketplaceId')}` +
      `&apikey=${this.props.cookies.get('apiKey')}`;

    fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify([
        { notificationId: item.notificationId, markAsRead: true },
      ]),
    })
      .then(res => res.json())
      .catch(error => console.error('Error:', error))
      .then(response => this.loadNotifications(getConfig('marketplaceId')));
  };

  markAsUnread(item) {
    const notificationItems = this.props.notifications.items[0].receiverUserId;

    const url =
      `${getConfig('apiRoot')}notification-read` +
      `?userid=${notificationItems}` +
      `&mpId=${getConfig('marketplaceId')}` +
      `&apikey=${this.props.cookies.get('apiKey')}`;

    fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify([
        { notificationId: item.notificationId, markAsRead: false },
      ]),
    })
      .then(res => res.json())
      .catch(error => console.error('Error:', error))
      .then(response => this.loadNotifications(getConfig('marketplaceId')));
  }
 
  createNotificationList() {
    const notificationItems = this.props.notifications.items;
    const items = values(notificationItems).map((item, index) => {
      const notificationTitle = alertTypes.find(i => i.id === item.notificationTypeId)?.notificationTitle
      const notificationLink = item.notificationTypeId === 1040 ? `/savedsearches` : `/item/${item.listingId}`
      if (item.notificationTypeId !== 1030 && item.markAsRead === false) {
        return (
          <li key={index}>
            <div>
              <div
                className="col-md-1"
                style={{ textAlign: 'center', paddingTop: '12px' }}
              >
                <span
                  className="fas fa-circle"
                  style={{ color: 'rgb(92, 190, 127)' }}
                >
                  {' '}
                </span>
              </div>
              <div className="col-md-8 no-padding">
                <h4>
                  <Link
                    to={notificationLink}
                    className="notification-type"
                  >
                    {notificationTitle}
                  </Link>{' '}
                  <br /> {item.subject}
                </h4>
                <p>{stripHtml(item.message)}</p>
              </div>
              <div className="notification-item__footer col-md-3 text-right" style={{ marginTop: '8px' }}>
                {moment.tz(`${item.dateCreated}`, timezone).fromNow()}
                <br />
                {item.notificationTypeId !== 1040
                  ? (<Link
                    to={notificationLink}
                    onClick={() => {
                      this.markAsRead(item);
                      sessionStorage.removeItem('navItems');
                    }}
                    className="notification-item__footer_viewdetails btn btn-primary"
                    // style={{ marginTop: '32px' }}
                    >
                      View Vehicle Details
                    </Link>)
                  : null
                }
                <Link
                  to="#"
                  onClick={() => this.markAsReadQuick(item)}
                  className="notification-item__footer_markasread btn btn-primary notification-type-read"
                  style={{ minWidth: '152.83px' }}
                >
                  Mark As Read
                </Link>
              </div>
            </div>
          </li>
        );
      } else {
        return (
          <li key={index} style={{ backgroundColor: '#f5f3f3' }}>
            <div>
              <div
                className="col-md-1"
                style={{ textAlign: 'center', paddingTop: '12px' }}
              >
                <span
                  className="far fa-circle"
                  style={{ color: 'rgb(92, 190, 127)' }}
                >
                  {' '}
                </span>
              </div>
              <div className="col-md-8 no-padding">
                <h4>
                  <Link
                    to={notificationLink}
                    className="notification-type"
                    style={{ marginBottom: '5px' }}
                  >
                    {notificationTitle}
                  </Link>
                  <br /> {item.subject}
                </h4>
                <p>{stripHtml(item.message)}</p>
              </div>
              <div className="col-md-3 text-right" style={{ marginTop: '8px' }}>
                {moment.tz(`${item.dateCreated}`, timezone).fromNow()}
                <br />
                {item.notificationTypeId !== 1040
                  ? (
                    <Link
                      to={`/item/${item.listingId}`}
                      onClick={() => {
                        this.markAsRead(item);
                        sessionStorage.removeItem('navItems');
                      }}
                      className="notification-item__footer_viewdetails btn btn-primary"
                      // style={{ marginTop: '32px' }}
                      >
                        View Vehicle Details
                    </Link>)
                  : null
                }
                <Link
                  to="#"
                  onClick={() => this.markAsUnread(item)}
                  className="notification-item__footer_markasread btn btn-primary"
                  style={{ minWidth: '152.83px' }}
                >
                  Mark As Unread
                </Link>
              </div>
            </div>
          </li>
        );
      }
    });

    return items;
  }

  render() {
    const title = getConfig('name')
      ? getConfig('name') + ' | Notifications'
      : 'Notifications';

    return (
      <DocumentTitle title={title}>
        <div>
          <div className="search-nav">
            <div className="container">
              <div className="search-title pull-left">
                <h4>Notifications</h4>
              </div>
            </div>
          </div>
          <div
            className="container"
            style={{ backgroundColor: '#f9f9f9', marginTop: 10, padding: 15 }}
          >
            <p style={{ marginTop: 10 }}>
              These are notifications that you are subscribed to. You can update
              those setting by going to alerts in your{' '}
              <Link to="/profile">profile</Link>.
            </p>
            <p>
              To view your activity timeline go{' '}
              <Link to="/myactivity" state={{ routeName: 'My Activity' }}>
                here
              </Link>
              .
            </p>
            <hr />
            <ul className="notification-list">
              {this.createNotificationList()}
            </ul>
          </div>
        </div>
      </DocumentTitle>
    );
  }
}

Notifications.propTypes = {
  loadNotifications: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  const { notifications } = state.entities;
  return { notifications };
};

export default connect(
  mapStateToProps,
  { loadNotifications }
)(Notifications);
